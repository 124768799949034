import React, { useEffect } from 'react'
import {
    TheContent,
    TheSidebar,
    TheFooter,
    TheHeader
} from './index'
import {
    useHistory
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { activeTheSideBar } from "../SLice/activeSideBar";

const TheLayout = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    
  

    useEffect(() => {
        let path = history?.location?.pathname === "/" ? 'user' : history.location.pathname.split("/")[1]
        dispatch(activeTheSideBar(path))

    }, [history.location.pathname])
    return (
        <div className="c-app c-default-layout">
            <TheSidebar />
            <div className="c-wrapper" style={{ background: "#FFFFFF" }}>
                <div className="">
                    <TheContent />
                </div>
                <TheFooter />
            </div>
        </div>
    )
}

export default TheLayout
