import React, { useEffect } from "react";
import NavSuper from "./nav-superadmin";
import NavAdmin from "./nav-admin";
import {
  CCreateElement,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import { useSelector } from "react-redux";

function Nav() {
  const admin = JSON.parse(localStorage.getItem("user"));

  const withdraw = useSelector((state) => {
    return state.withdraw
  })
  const report = useSelector((state) => {
    return state.report
  })
  const expert = useSelector((state) => {
    return state.expert
  })

  useEffect(() => {
  }, [withdraw, report, expert])


  return (
    <div>
      {admin?.type === "SUPERADMIN" ? (
        <CCreateElement
          items={NavSuper}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,

          }}
        />
      ) : (
        <CCreateElement
          items={NavAdmin}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,

          }}
        />
      )}
    </div>
  );
}

export default Nav;
