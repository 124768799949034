import { useSelector } from "react-redux";
import { useEffect } from "react";
const Report = () => {
  const report = useSelector((state) => {
    return state.report;
  });

  const reportLocal = localStorage.getItem("report");

  useEffect(() => {
    console.log("report ", report);
  }, [report]);

  return (
    <div className={report > 0 || reportLocal > 0 ? "badge-report" : ""}>
      {report || reportLocal > 0 ? reportLocal : ""}
    </div>
  );
};

const Withdraw = () => {
  const withdraw = useSelector((state) => {
    return state.withdraw;
  });

  const withdrawLocal = localStorage.getItem("withdraw");

  useEffect(() => {
    console.log("withdraw ", withdraw);
  }, [withdraw]);

  return (
    <div className={withdraw > 0 || withdrawLocal > 0 ? "badge-report" : ""}>
      {withdraw || (withdrawLocal > 0 ? withdrawLocal : "")}
    </div>
  );
};

const Expert = () => {
  const expert = useSelector((state) => {
    return state.expert;
  });
  const expertLocal = localStorage.getItem("expert");
  useEffect(() => {
    console.log("expert ", expert);
  }, [expert]);

  return (
    <div className={expert > 0 || expertLocal > 0 ? "badge-report" : ""}>
      {expert || (expertLocal > 0 ? expertLocal : "")}
    </div>
  );
};

const navigation = [
  {
    _tag: "CSidebarNavItem",
    name: "相談者一覧",
    to: "/user",
    icon: <div></div>,
  },

  {
    _tag: "CSidebarNavItem",
    name: "専門家一覧",
    to: "/expert",
    icon: <Expert />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "報告一覧",
    to: "/report",
    icon: <Report />,
  },

  {
    _tag: "CSidebarNavItem",
    name: "振込管理",
    to: "/withdraw",
    icon: <Withdraw />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "分析",
    to: "/analyze",
    icon: <div></div>,
  },
];

export default navigation;
