import React, { useEffect, useState } from "react";
import { CHeader, CBreadcrumbItem } from "@coreui/react";

// routes config
import routes from "../routes";
import Back from "../assets/back-history.png";
import Avatar from "../assets/avatar.png";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ModalPopup } from "../views/Field/modalPopup";
import { RANKING, PRICE, RANK_NAME } from "src/Constant/cons";
const TheHeader = ({
  title,
  isDetail,
  isConverastion,
  user,
  onClick,
  type,
  onBlock,
  ranking,
}) => {
  const history = useHistory();
  const [modalControl, setModalControl] = useState({
    isOpen: false,
    item: {},
  });

  const renderRanking = () => {
    switch (ranking[0].title) {
      case RANK_NAME.NORMAL:
        return (
          <>
            <div
              style={{ padding: "4px 12px" }}
              className="badge-ranking normal d-flex align-items-center"
            >
              Normal
            </div>
            <p className="text-medium-f14">
              {ranking[0].price.toLocaleString("en-US")}円 / 月
            </p>
          </>
        );
      case RANK_NAME.BRONZE:
        return (
          <>
            <div
              style={{ padding: "4px 12px" }}
              className="badge-ranking bronze d-flex align-items-center"
            >
              Bronze
            </div>
            <p className="text-medium-f14">
              {ranking[0].price.toLocaleString("en-US")}円 / 月
            </p>
          </>
        );
      case RANK_NAME.SILVER:
        return (
          <>
            <div
              style={{ padding: "4px 12px" }}
              className="badge-ranking silver d-flex align-items-center"
            >
              Silver
            </div>
            <p className="text-medium-f14">
              {ranking[0].price.toLocaleString("en-US")}円 / 月
            </p>
          </>
        );
      case RANK_NAME.GOLD:
        return (
          <>
            <div
              style={{ padding: "4px 12px" }}
              className="badge-ranking gold d-flex align-items-center"
            >
              Gold
            </div>
            <p className="text-medium-f14">
              {ranking[0].price.toLocaleString("en-US")}円 / 月
            </p>
          </>
        );
      case RANK_NAME.PLATINUM:
        return (
          <>
            <div className="badge-ranking background-platina">
              <div
                style={{ padding: "4px 12px" }}
                className="platina d-flex align-items-center"
              >
                Platinum
              </div>
            </div>
            <p className="text-medium-f14">
              {ranking[0].price.toLocaleString("en-US")}円 / 月
            </p>
          </>
        );
      default:
      // code block
    }
  };

  return (
    <CHeader style={{ border: "none!important" }}>
      <CBreadcrumbItem
        style={{ width: "100%" }}
        className="border-0 c-subheader-nav m-0 px-0 px-md-3 breadcrumb-custom"
      >
        {title}
        {isDetail && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: 8,
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                onClick={() => history.goBack()}
                style={{ cursor: "pointer" }}
                src={Back}
              />
              <img
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: 24,
                  objectFit: "cover",
                }}
                src={user?.avatar || Avatar}
              />
              <div style={{ marginLeft: 16 }}>
                <div
                  style={{ maxWidth: 325, wordBreak: "break-word" }}
                  className="nick-name-user"
                >
                  {user?.fullname_kanji}
                </div>
                <div className="email-user">{user?.nickname}</div>
              </div>

              {user?.user_type === "EXPERT" && ranking.length > 0 ? (
                <div
                  className="d-flex align-items-center"
                  style={{
                    background: "rgba(86, 86, 86, 0.05)",
                    border: "1px solid rgba(86, 86, 86, 0.2)",
                    borderRadius: 42,
                    padding: "16px",
                    marginLeft: 24,
                    columnGap: 5,
                    width: 194,
                    height: 48,
                  }}
                >
                  {renderRanking()}
                </div>
              ) : user?.state === "NORMAL" ? (
                <div className="badge-info-withdraw">通常</div>
              ) : user?.state === "ADMIN_DELETE" ? (
                <div className="badge-red-withdraw">強制退会</div>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", columnGap: 8 }}>
              {!isConverastion ? (
                <>
                  <div
                    className="btn-withdraw"
                    onClick={() => history.push(`/inquiry/${user?.id}`)}
                  >
                    チャットを見る
                  </div>
                  {user?.user_type === "EXPERT" && (
                    <div
                      onClick={() => onClick("open")}
                      className="btn-withdraw"
                    >
                      ランク変更
                    </div>
                  )}
                  {user?.state === "NORMAL" && (
                    <div
                      onClick={() =>
                        setModalControl({ ...modalControl, isOpen: true })
                      }
                      className="btn-withdraw-red"
                    >
                      強制退会
                    </div>
                  )}
                </>
              ) : (
                <>
                  {user?.user_type === "EXPERT" && (
                    <div
                      onClick={() => onClick("open")}
                      className="btn-withdraw"
                    >
                      ランク変更
                    </div>
                  )}

                  {user?.state === "NORMAL" && (
                    <div
                      onClick={() =>
                        setModalControl({ ...modalControl, isOpen: true })
                      }
                      className="btn-withdraw-red"
                    >
                      強制退会
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </CBreadcrumbItem>
      <ModalPopup
        isOpen={modalControl.isOpen}
        item={user}
        setShowModel={(value) =>
          setModalControl({ ...modalControl, isOpen: false })
        }
        type={modalControl.type}
        onDelete={() => {
          Swal.fire({
            customClass: "a",
            title: "強制退会させますか？",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "強制退会する",
            cancelButtonColor: "#d33",
            cancelButtonText: "キャンセル",
          }).then((result) => {
            if (result.isConfirmed) {
              onBlock(user.id);
              setModalControl({ ...modalControl, isOpen: false });
              Swal.fire("強制退会ステータスに変更されました");
            }
          });
        }}
      />
    </CHeader>
  );
};

export default TheHeader;
