import React, { Suspense, useEffect, useState } from 'react'
import {
    Redirect,
    Route,
    Switch,
    useHistory
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'
import { useSelector } from 'react-redux';

// routes config
import routes from '../routes'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)


const TheContent = () => {

    const inquiryShow = useSelector((state) => {
        return state.inquiryShow
    })

 


    return (
        <main className="c-main">
            <CContainer fluid className={inquiryShow ? 'pl--0' : ""}>
                <Suspense fallback={loading}>
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                        <CFade>
                                            <route.component {...props} />
                                        </CFade>
                                    )} />
                            )
                        })}

                    </Switch>
                </Suspense>
            </CContainer>
        </main>
    )
}

export default React.memo(TheContent)
