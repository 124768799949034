import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import 'rsuite/dist/rsuite.min.css';
import './scss/style.scss';
import { Provider, useSelector } from 'react-redux'
import store from './store'
import 'react-datepicker/dist/react-datepicker.css'; // Main style file


// import 'bootstrap/dist/css/bootstrap.css';


ReactDOM.render(
  <Provider store={store}>

    <App />

  </Provider>,
  document.getElementById('root')
);

