import { createSlice } from "@reduxjs/toolkit";

const token = !!JSON.parse(localStorage.getItem('token'))


const inquiryShow = createSlice({
    name: "inquiryShow",
    initialState: false,
    reducers: {
        showInquiry: (state, action) => {
            state = action.payload
            let a = document.getElementsByClassName('c-sidebar-nav-link')
            for (let i = 0; i < a.length; i++) {
                console.log()
                if (a[i].getAttribute('href').includes("expert")) {
                    a[i].classList.add('c-active')
                }
            }
            return state;
        },
    }
})

const { reducer, actions } = inquiryShow;
export const { showInquiry } = actions;
export default reducer;
