import { createSlice } from "@reduxjs/toolkit";

const withdraw = createSlice({
  name: "withdraw",
  initialState: 0,
  reducers: {
    countWithdraw: (state, action) => {
      state = action.payload;
      console.log("withdraw", action.payload);
      localStorage.setItem("withdraw", action.payload);
      return state;
    },
  },
});

const { reducer, actions } = withdraw;
export const { countWithdraw } = actions;
export default reducer;
