import { createSlice } from "@reduxjs/toolkit";

const report = createSlice({
  name: "report",
  initialState: 0,
  reducers: {
    countReport: (state, action) => {
      state = action.payload;
      localStorage.setItem("report", action.payload);
      return state;
    },
  },
});

const { reducer, actions } = report;
export const { countReport } = actions;
export default reducer;
