import { createSlice } from "@reduxjs/toolkit";

const expert = createSlice({
  name: "expert",
  initialState: 0,
  reducers: {
    countExpert: (state, action) => {
      state = action.payload;
      localStorage.setItem("expert", action.payload);
      return state;
    },
  },
});

const { reducer, actions } = expert;
export const { countExpert } = actions;
export default reducer;
