import { configureStore } from '@reduxjs/toolkit'
import sidebarShow from './SLice/responsive'
import employee from "./SLice/Employee"
import Modal from './views/Modal';
import auth from './SLice/auth';
import inquiryShow from './SLice/inquiryShow';
import activeTheSideBar from "./SLice/activeSideBar";
import report from './SLice/report';
import withdraw from './SLice/withdraw';
import expert from './SLice/count_expert';
const rootReducer = {
  sidebarShow: sidebarShow,
  inquiryShow: inquiryShow,
  employee: employee,
  modal: Modal,
  auth: auth,
  activeTheSideBar: activeTheSideBar,
  report,
  withdraw,
  expert
}

const store = configureStore({
  reducer: rootReducer,
});

export default store;
