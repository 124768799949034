import React, { useState, useEffect } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "./file.css";

import Avatar from "../../assets/avatar.png";

const ModalPopup = (props) => {
  const { isOpen, isClose, setShowModel, item, onDelete, reported } = props;

  // console.log(reported);
  // console.log(item);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setModal(isOpen);
    setModal(isClose);
  };

  return (
    <>
      <CButton onClick={toggle} className="mr-1"></CButton>
      <CModal
        size={"xl"}
        centered={true}
        show={isOpen}
        onClose={() => setShowModel(false)}
      >
        <CModalHeader
          style={{ border: "none" }}
          className="title-modal"
          closeButton
        >
          強制退会しますか？
        </CModalHeader>
        <CModalBody style={{ padding: 24 }}>
          <div
            className="box-name-user"
            style={{
              background: "#FBFCFF",
              border: "1px solid #D9D9D9",
              borderRadius: "8px",
              padding: 22,
            }}
          >
            {reported && reported.id ? (
              <>
                <img
                  style={{ width: 24, height: 24 }}
                  src={reported?.avatar ?? Avatar}
                />
                <div>
                  <div className="nick-name-user">
                    {reported?.fullname_kanji}
                  </div>
                  <div className="email-user">{reported?.email}</div>
                </div>
              </>
            ) : (
              <>
                <img
                  style={{ width: 24, height: 24 }}
                  src={item?.avatar ?? Avatar}
                />
                <div>
                  <div className="nick-name-user">{item?.fullname}</div>
                  <div className="email-user">{item?.email}</div>
                </div>
              </>
            )}
          </div>
        </CModalBody>
        <CModalFooter
          style={{
            justifyContent: "center",
            flexWrap: "nowrap",
            paddingTop: 36,
            border: "none",
          }}
        >
          <div
            onClick={() => setShowModel(false)}
            className="btn-tranparent"
            style={{
              width: "48%",
            }}
          >
            キャンセル
          </div>
          <div
            onClick={() => onDelete()}
            style={{
              width: "48%",
            }}
            className="btn-red"
          >
            強制退会する
          </div>
        </CModalFooter>
      </CModal>
    </>
  );
};

const ModalConfirm = (props) => {
  const { isOpen, isClose, setShowModel, item, onConfirm } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setModal(isOpen);
    setModal(isClose);
  };

  return (
    <>
      <CButton onClick={toggle} className="mr-1"></CButton>
      <CModal
        size={"sm"}
        centered={true}
        show={isOpen}
        onClose={() => setShowModel(false)}
      >
        <CModalHeader
          style={{ border: "none", textAlign: "center" }}
          className="title-modal-edit"
          closeButton
        >
          ログアウトしますか？
        </CModalHeader>

        <CModalFooter
          style={{
            justifyContent: "center",
            flexWrap: "nowrap",
            paddingTop: 36,
            border: "none",
          }}
        >
          <div
            onClick={() => setShowModel(false)}
            className="btn-tranparent"
            style={{
              width: "48%",
            }}
          >
            キャンセル
          </div>
          <div
            onClick={() => onConfirm(true)}
            style={{
              width: "48%",
              height: 48,
              fontSize: 16,
              padding: "12px 14px",
            }}
            className="btn-withdraw"
          >
            ログアウト
          </div>
        </CModalFooter>
      </CModal>
    </>
  );
};

export { ModalPopup, ModalConfirm };
