import React from "react";

const DetailUser = React.lazy(() => import("./views/employee/Detail"));

const DetailExpert = React.lazy(() => import("./views/employee/DetailExpert"));

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

const EmployeeList = React.lazy(() => import('./views/employee/EmployeeList'));

const Report = React.lazy(() => import('./views/report/Report'));

const InquiryList = React.lazy(() => import('./views/inquiry/InquiryList'));

const Withdraw = React.lazy(() => import("./views/withdraw/Withdraw"));

const Analyze = React.lazy(() => import("./views/analyze/Analyze"));

const VerifyPage = React.lazy(() => import("./views/verify/index"));

const routes = [

    { path: '/', exact: true, name: '相談者一覧', component: Dashboard },

    { path: '/user', exact: true, name: '相談者一覧', component: Dashboard },

    { path: '/user/:id', name: '専門家一覧', component: DetailUser, exact: true },

    { path: '/expert', name: '専門家一覧', component: EmployeeList, exact: true },

    { path: '/expert/:id', name: '専門家一覧', component: DetailExpert, exact: true },

    { path: '/report', name: '報告一覧', component: Report, exact: true },

    { path: '/inquiry/:id', name: 'Inquiry', component: InquiryList, exact: true },

    { path: "/withdraw", name: "Withdraw", component: Withdraw, exact: true },

    { path: "/analyze", name: "Analyze", component: Analyze, exact: true },


]


export default routes;
