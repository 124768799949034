import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <>
    </>
  )
}

export default React.memo(TheFooter)
