import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BackGroundLoGo from "../assets/back.png";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
} from "@coreui/react";


// sidebar nav config
import Nav from './_nav'
import { set } from 'src/SLice/responsive'
import { useHistory } from "react-router-dom";
import { ModalConfirm } from "../views/Field/modalPopup";

const TheSidebar = () => {
  const dispatch = useDispatch()
  const history = useHistory();

  const show = useSelector(state => state.sidebarShow)
  const [modalControl, setModalControl] = useState({
    isOpen: false,
  });
  return (
    <CSidebar className="the-sidebar"
      show={show}
      onShowChange={(val) => dispatch(set(val))}
    >
      <CSidebarBrand
        style={{ background: "#FFFFFF" }}
        className="d-md-down-none"
        to="/user"
      >
        <div style={{ padding: "24px 0", maxWidth: 200 }}>
          <img style={{ width: "100%" }} src={BackGroundLoGo} />
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <Nav />
        <div className="log-out" onClick={() => {
          setModalControl({
            ...modalControl,
            isOpen: true
          })

        }}>ログアウト</div>


      </CSidebarNav>
      {/*<CSidebarMinimizer className="c-d-md-down-none" />*/}

      <ModalConfirm
        isOpen={modalControl.isOpen}
        setShowModel={(value) => setModalControl({ ...modalControl, isOpen: false })}
        onConfirm={(value) => {
          localStorage.clear()
          history.push("/login")
        }}
      />
    </CSidebar>


  );
};

export default React.memo(TheSidebar);
