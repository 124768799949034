import { createSlice } from "@reduxjs/toolkit";

const token = !!JSON.parse(localStorage.getItem('token'))


const auth = createSlice({
    name: "auth",
    initialState: token,
    reducers: {
        saveToken: (state, action) => {
            state = action.payload
            return state;
        },
    }
})

const { reducer, actions } = auth;
export const { saveToken } = actions;
export default reducer;