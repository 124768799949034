export const OPTION_TYPE = [{ name: "ADMIN" }];

export const RANKING = {
  PLATINUM: "15409a40-0e72-11ee-9684-0bcaa89af1a9",
  GOLD: "0f6a7640-0e72-11ee-9684-0bcaa89af1a9",
  SILVER: "09858530-0e72-11ee-9684-0bcaa89af1a9",
  BRONZE: "ff53f650-0e71-11ee-9684-0bcaa89af1a9",
  NORMAL: "f8d73210-0e71-11ee-9684-0bcaa89af1a9",
};

export const RANK_NAME = {
  PLATINUM: "PLATINUM",
  GOLD: "GOLD",
  SILVER: "SILVER",
  BRONZE: "BRONZE",
  NORMAL: "NORMAL",
};

export const STATE_CONVERSATION = {
  NORMAL: "NORMAL",
  OUT_GROUP: "OUT_GROUP",
  END_CONTRACT: "END_CONTRACT",
};

export const WITHDRAW_STATE = {
  PENDING: "PENDING",
  DENY: "DENY",
  ACCEPT: "ACCEPT",
  TRANSFER_SUCCESS: "TRANSFER_SUCCESS",
  REFUND_SUCCESS: "REFUND_SUCCESS",
};

export const STATUS = {
  ACCEPT: "ACCEPT",
  DENY: "DENY",
};

export const HOST_REGISTER_STATUS = {
  PENDING: "PENDING",
  DENY: "DENY",
  ACCEPT: "ACCEPT",
};

export const PRICE = {
  PLATINUM: "12,760",
  GOLD: "6,380",
  SILVER: "3,960",
  BRONZE: "2,750",
  NORMAL: "1,540",
};

export const TRANSACTION_TYPE = {
  EXTRA: "EXTRA",
  SUBSCRIPTION: "SUBSCRIPTION",
};

export const MESSAGE_TYPE = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  SYSTEM_NO_REPLY: "SYSTEM_NO_REPLY",
  SYSTEM_OUT_GROUP: "SYSTEM_OUT_GROUP",
  SYSTEM_END_CONTRACT: "SYSTEM_END_CONTRACT",
  PDF: "PDF",
  VIDEO: "VIDEO",
};
