import { createSlice } from "@reduxjs/toolkit";


const activeSideBar = createSlice({
    name: "activeSideBar",
    initialState: "user",
    reducers: {
        activeTheSideBar: (state, action) => {
            state = action.payload
            let a = document.getElementsByClassName('c-sidebar-nav-link')
            for (let i = 0; i < a.length; i++) {
                if (a[i].getAttribute('href').includes(state)) {
                    a[i].classList.add('c-active')
                }
            }
            for (let i = 0; i < a.length; i++) {
                if (!a[i].getAttribute('href').includes(state)) {
                    a[i].classList.remove('c-active')
                }
            }

            return state;
        },
    }
})

const { reducer, actions } = activeSideBar;
export const { activeTheSideBar } = actions;
export default reducer;
