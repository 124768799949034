import React, { Suspense } from "react";
import "./scss/style.scss";
import { TheLayout } from "./containers";
import { icons } from "./assets/icons";
import {
    HashRouter,
    Route,
    Switch,
    Redirect,
    BrowserRouter,
    Routes,
    useHistory,
} from "react-router-dom";
import { useSelector } from "react-redux";
import VerifyPage from "./views/verify";

React.icons = icons;
const Login = React.lazy(() => import("./views/pages/login/Login"));
const CheckOut = React.lazy(() => import("./views/subcription/Subcription"));

function App() {
    const auth = JSON.parse(localStorage.getItem('token'))
    const loading = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    );



    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={(props) =>
                auth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );

    return (
        <div>
            <HashRouter>
                <Suspense fallback={loading}>
                    <Switch>

                        <Route path="/login" name="Login" component={Login} />

                        <Route path="/checkout" name="Checkout" component={CheckOut} />

                        <Route path="/verify-page" name="verify" component={VerifyPage} />

                        <PrivateRoute path="/" component={TheLayout} />

                        <PrivateRoute path="/user" component={TheLayout} />

                        <PrivateRoute path="/user/:id" component={TheLayout} />

                        <PrivateRoute path="/expert" component={TheLayout} />

                        <PrivateRoute path="/expert/:id" component={TheLayout} />

                        <PrivateRoute path="/inquiry/:id" component={TheLayout} />

                        <PrivateRoute path="/report" component={TheLayout} />

                        <PrivateRoute path="/withdraw" component={TheLayout} />

                        <PrivateRoute path="/analyze" component={TheLayout} />

                        <PrivateRoute path="/tranfer" component={TheLayout} />


                    </Switch>

                </Suspense>


            </HashRouter>
        </div>
    );
}

export default App;
